$primary-color: #d122e3;
$hover-color: darken($primary-color, 10%);

.floating-contact {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;

  .contact-button {
    width: 60px;
    height: 60px;
    background: $primary-color;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;

    &:hover {
      background: $hover-color;
    }
  }

  .contact-options {
    position: absolute;
    bottom: 70px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

    a {
      width: 50px;
      height: 50px;
      background: #f8f9fa;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      font-size: 20px;
      transition: transform 0.3s ease-in-out;

      // 🚀 Fix the unwanted line under icons
      text-decoration: none !important;
      line-height: 1 !important;
      vertical-align: middle !important;
      padding: 0 !important;
      border: none !important;
      box-shadow: none !important;
      overflow: hidden;

      i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        line-height: 1 !important;
      }

      &:hover {
        transform: scale(1.1);
      }

      &.whatsapp {
        color: #25d366;
      }
      &.instagram {
        color: #e4405f;
      }
      &.map {
        color: #ff5722;
      }
      &.call {
        color: #007bff;
      }
    }
  }

  &.open .contact-options {
    opacity: 1;
    visibility: visible;
  }
}
