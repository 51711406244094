@use "../../customs" as *;

.review-form-container {
  max-width: 500px;
  margin: 2rem auto;
  padding: 1.5rem;
  background: linear-gradient(135deg, $purple-light, $pink);
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-align: center;

  h2 {
    color: $white;
    font-family: "Berkshire Swash", cursive;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
}

.review-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      font-weight: bold;
      color: $white;
      margin-bottom: 0.5rem;
    }
    input,
    textarea {
      width: 100%;
      padding: 0.7rem;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 8px;
      font-size: 1rem;
      background: rgba(255, 255, 255, 0.2);
      color: $white;
    }
    textarea {
      resize: vertical;
      height: 100px;
    }
  }

  .submit-btn {
    background: $gray-900;
    color: $white;
    padding: 0.8rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background 0.3s;

    &:hover {
      background: $purple-dark;
    }
  }
}

@media (max-width: 768px) {
  .review-form-container {
    max-width: 90%;
    padding: 1.2rem;
  }
  .review-form {
    gap: 0.8rem;
  }
}
