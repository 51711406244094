@use "../../customs" as *;
@use "../../componenets/mixins" as *;
@use "../../componenets/buttons";
@use "../../componenets/typography";
@import "../../fontawesome.scss";

.mx-auto {
  width: 100px;
  height: 3px;
}

.testimonials {
  position: relative;
  padding: 0;
  @include gradient;
  &__container {
    margin: 1rem 0;
    padding: 1rem 0;
  }

  &__card {
    background-color: rgba($white, 0.8);
    padding: 1rem;
    border-radius: 0.625rem;
    height: auto;
    text-align: center;
    margin: 0.5rem;
    i {
      font-size: 1.5rem;
      color: rgba($primary, 0.7);
    }
    @include media-breakpoint-down(sm) {
      font-size: 0.8rem;
      margin-right: 1rem !important;
      padding-right: 1rem !important;
      margin-top: 2rem !important;
      max-width: 80% !important;
    }

    .ratings {
      i {
        color: rgba($primary, 0.7);
        font-size: 1rem;
      }
    }
  }

  &__picture {
    max-height: 13rem;
    max-width: 13rem;
    display: flex;
    justify-content: center;
    align-items: center; /* Centers the image within the div */
  }

  &__image {
    max-width: 100%; /* Ensures the image scales according to the container width */
    max-height: 100%; /* Ensures the image scales according to the container height */
    object-fit: contain; /* Ensures the image is fully contained without cropping */
  }

  &__name {
    padding-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 500;
    font-family: "Berkshire Swash", cursive;
    color: rgba($primary, 0.7);

    h3 {
      padding-top: 0.8rem;
      text-transform: capitalize;
    }

    p {
      padding-bottom: 2rem;
      text-transform: capitalize;
    }
  }
}
